export default [{
  path: '/dashboard-chamados',
  component: {
    resource: 'dashchamados',
    action: 'read',
    name: 'dashboardChamados',
    template: '<router-view />',
    meta: {
      resource: 'dashchamados',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'dashboard-chamados',
    component: () => import('@/app/dashboards/colaboradores-internos/ColaboradoresInternosView.vue'),
    meta: {
      resource: 'dashchamados',
      action: 'read',
      navActiveLink: 'dashboard-chamados',
      pageTitle: 'Dashboard Chamados',
      breadcrumb: [
        {
          text: 'Dashboard Chamados',
          to: { name: 'dashboard-chamados' },
          active: true,
        },
      ],
    },
  }],
}]
