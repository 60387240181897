import home from '@/app/home/router'
import login from '@/app/login/router'
import usuario from '@/app/usuario/router'
import grupo from '@/app/grupo/router'
import perfil from '@/app/perfil-empresa/router'
import link from '@/app/rede-link/router'
import host from '@/app/host/router'
import categoria from '@/app/categoria/router'
import cliente from '@/app/cliente/router'
import revenda from '@/app/revenda/router'
import rotina from '@/app/rotina/router'
import script from '@/app/script/router'
import categoriaProduto from '@/app/categoria-produto/router'
import produto from '@/app/produto/router'
import etiqueta from '@/app/etiqueta/router'
import naturezaoperacao from '@/app/natureza-operacao/router'
import notificacao from '@/app/notificacao/router'
import chamado from '@/app/chamado/router'
import tiposervico from '@/app/tipo-servico/router'
import dashboardChamados from '@/app/dashboards/colaboradores-internos/router'
import logGeral from '@/app/log-geral/router'
import sistema from '@/app/sistema/router'
import agenda from '@/app/agenda/router'
import indiceReajuste from '@/app/indice-reajuste/router'
import quadroImplantacao from '@/app/quadro-implantacao/router'
import contrato from '@/app/contrato/router'
import tipoAtendimento from '@/app/tipo-atendimento/router'
import nivelAtendimento from '@/app/nivel-atendimento/router'
import categoriasCompromisso from '@/app/agenda/categorias-compromissos/router'

export default [
  ...home,
  ...login,
  ...usuario,
  ...grupo,
  ...perfil,
  ...link,
  ...host,
  ...categoria,
  ...revenda,
  ...cliente,
  ...rotina,
  ...script,
  ...categoriaProduto,
  ...produto,
  ...etiqueta,
  ...naturezaoperacao,
  ...notificacao,
  ...chamado,
  ...tiposervico,
  ...dashboardChamados,
  ...logGeral,
  ...sistema,
  ...agenda,
  ...indiceReajuste,
  ...quadroImplantacao,
  ...contrato,
  ...tipoAtendimento,
  ...nivelAtendimento,
  ...categoriasCompromisso,
]
