import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  isAdmin,
  isGoingToLogging,
  isUserLoggedIn,
  permitidoAcessarRecurso,
} from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import appRoutes from '../app/router/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: appRoutes,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (!isGoingToLogging(from, to)) {
    if (to.meta.requiredAuth === false) {
      next()
      return
    }

    if (isUserLoggedIn()) {
      if (isAdmin() || (!isAdmin() && canNavigate(to))) {
        if (to.fullPath === '/' && permitidoAcessarRecurso('dashchamados')) {
          next({
            path: '/dashboard-chamados/visualizar',
          })
          return
        }
        next()
        return
      }

      if (!canNavigate(to)) {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
        return
      }
    }
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
    return
  }

  if (to.path !== '/login') {
    next('/login')
  } else {
    next()
  }
})

export default router
